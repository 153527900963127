<template>
  <q-list padding dense class="menu-active-config config-menu">
    <template v-for="(ruta, index) in rutas">
      <q-item clickable v-ripple exact
              :to="{name: ruta.ruta}"
              :key="index" :class="{ 'q-item--active': validarActiveRuta(ruta) }">
        <q-item-section avatar class="q-pr-md mw0">
          <q-icon :name="ruta.icono" size="18px"/>
        </q-item-section>
        <q-item-section>
          <q-item-label class="fs-11">{{ruta.nombre}}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-list>
</template>

<script>
  export default {
    name: "menuConfiguraciones",
    data() {
      return {
        rutas: [
          {nombre: "Perfiles", ruta: "perfilGestor", icono: "las la-user-friends", rutasRelacionadas: []},
        ],
      }
    },
    methods: {
      validarActiveRuta(r) {
        return (this.$route.name === r.ruta || _.filter(r.rutasRelacionadas, (rr) => this.$route.name === rr).length > 0)
      },
    }
  }
</script>
